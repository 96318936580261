import React, { PureComponent } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  AthleteProfileResponse,
  editProfile,
} from '../../../../services/fetch/profile'
import * as S from './EditProfile.styles'
import Snack from '../../../Snackbar'
import { EditProfPicModal } from '../../../profile/EditProfPicModal'
import { IconCircle, EditIcon } from '../../../shared'
import { booleanType } from 'aws-sdk/clients/iam'
import styled from 'styled-components'
import { InitialsCircle } from '../../../profile/InitialsCircle'
import { WHITE } from '../../../../constants/colors'

interface State {
  firstName: string
  lastName: string
  location: string
  email: string
  phoneNumber: string
  isUpdating: boolean
  open: boolean
  isSuccess: boolean
  message: string
  profilePictureURLState: string | undefined
  editProfPicActive: booleanType
}

interface Props {
  profile: AthleteProfileResponse | undefined
  updateProfile(): void
}

const Image = styled.img`
  height: 120px;
  width: 120px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  margin-bottom: 0px;
  border: 2px solid ${WHITE};
`

const ImageWrap = styled.div`
  position: relative;
`

class EditProfile extends PureComponent<Props, State> {
  state = {
    firstName: this.props.profile?.firstName || '',
    lastName: this.props.profile?.lastName || '',
    location: this.props.profile?.location || '',
    email: this.props.profile?.email || '',
    phoneNumber: this.props.profile?.phoneNumber || '',
    profilePictureURLState: this.props.profile?.profilePictureUrl || undefined,
    isUpdating: false,
    open: false,
    isSuccess: false,
    message: '',
    editProfPicActive: false,
  }

  setEditProfPicActive = (value: boolean) => {
    this.setState({ editProfPicActive: value })
  }

  setProfilePictureURLState = (pic: string) => {
    this.setState({ profilePictureURLState: pic })
  }

  EditIconCircle = (): React.ReactElement => (
    <IconCircle
      onClick={(): void => this.setEditProfPicActive(true)}
      style={{
        position: 'absolute',
        right: 'calc(15% - 1rem)',
        top: 'calc(15% - 1rem)',
      }}
    >
      <EditIcon color={WHITE} />
    </IconCircle>
  )

  canSubmit = () => {
    const { profile } = this.props
    const { firstName, lastName, location, email, phoneNumber } = this.state

    const currFirstName = profile?.firstName || ''
    const currLastName = profile?.lastName || ''
    const currLocation = profile?.location || ''
    const currEmail = profile?.email || ''
    const currPhoneNumber = profile?.phoneNumber || ''

    if (
      currFirstName === firstName &&
      currLastName === lastName &&
      currLocation === location &&
      currEmail === email &&
      currPhoneNumber === phoneNumber
    ) {
      return false
    }
    return true
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { firstName, lastName, location, email, phoneNumber } = this.state
    const { profile, updateProfile } = this.props

    this.setState({
      isUpdating: true,
    })

    editProfile({
      username: profile?.username as string,
      firstName,
      lastName,
      location,
      email: email.toLowerCase().trim(),
      phoneNumber,
    })
      .then(() => {
        this.setState({
          open: true,
          isSuccess: true,
          message: 'Successfully updated your profile!',
          isUpdating: false,
        })

        updateProfile()
      })
      .catch(err => {
        this.setState({
          open: true,
          isSuccess: false,
          message: err.message || 'Something went wrong updating your profile',
          isUpdating: false,
          firstName: this.props.profile?.firstName || '',
          lastName: this.props.profile?.lastName || '',
          location: this.props.profile?.location || '',
          email: this.props.profile?.email || '',
          phoneNumber: this.props.profile?.phoneNumber || '',
        })
      })
      .finally(() => {
        this.setState({
          isUpdating: false,
        })
      })
  }

  render() {
    const {
      firstName,
      lastName,
      location,
      email,
      phoneNumber,
      open,
      isSuccess,
      message,
      profilePictureURLState,
      isUpdating,
      editProfPicActive,
    } = this.state

    const isOwnProfile = true

    // todo placeholder should be props passed in
    return (
      <S.Container>
        <EditProfPicModal
          profileData={{
            username: this.props.profile?.username as string,
            firstName,
            lastName,
            location,
            email,
            phoneNumber,
          }}
          callback={(res): void =>
            this.setProfilePictureURLState(res.profilePictureUrl)
          }
          active={editProfPicActive}
          setActive={this.setEditProfPicActive}
        />

        {profilePictureURLState ? (
          <ImageWrap>
            <Image src={profilePictureURLState} />
            <this.EditIconCircle />
          </ImageWrap>
        ) : (
          <InitialsCircle firstName={firstName} lastName={lastName}>
            {isOwnProfile && <this.EditIconCircle />}
          </InitialsCircle>
        )}

        <form onSubmit={this.handleSubmit}>
          <S.FormWrap>
            <S.Label>First Name</S.Label>
            <S.FormInputDiv>
              <S.FormInput
                type="text"
                name="firstName"
                placeholder="First Name"
                value={firstName}
                onChange={e => this.setState({ firstName: e.target.value })}
                data-test={'editProfileFirstName'}
              />
            </S.FormInputDiv>
            <S.Label>Last Name</S.Label>
            <S.FormInputDiv>
              <S.FormInput
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={lastName}
                onChange={e => this.setState({ lastName: e.target.value })}
                data-test={'editProfileLastName'}
              />
            </S.FormInputDiv>
            <S.Label>Location</S.Label>
            <S.FormInputDiv>
              <S.FormInput
                type="text"
                name="location"
                placeholder="Location"
                value={location}
                onChange={e => this.setState({ location: e.target.value })}
                data-test={'editProfileLocation'}
              />
            </S.FormInputDiv>
            <S.Label>Email</S.Label>
            <S.FormInputDiv>
              <S.FormInput
                type="text"
                name="email"
                placeholder="Email"
                value={email}
                onChange={e => this.setState({ email: e.target.value })}
                data-test={'editProfileEmail'}
              />
            </S.FormInputDiv>
            <S.Label>Phone Number</S.Label>
            <S.FormInputDiv style={{ marginBottom: 0 }}>
              <S.FormInput
                type="text"
                name="phoneNumber"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={e => this.setState({ phoneNumber: e.target.value })}
                data-test={'editProfilePhone'}
              />
            </S.FormInputDiv>
            <S.ActionButton type="submit" isActive={this.canSubmit()} data-test={'editProfileSubmit'}>
              <S.ActionText isActive={this.canSubmit()}>
                Save Changes
              </S.ActionText>
              {isUpdating && (
                <S.SpinnerWrap>
                  <CircularProgress size={18} color="secondary" />
                </S.SpinnerWrap>
              )}
            </S.ActionButton>
          </S.FormWrap>
        </form>
        <Snack
          open={open}
          isSuccess={isSuccess}
          message={message}
          close={() =>
            this.setState({
              open: false,
            })
          }
        />
      </S.Container>
    )
  }
}

export default EditProfile
