import React, { PureComponent } from 'react'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress'
import { navigate } from 'gatsby'

import * as S from '../ManageMembership.styles'
import SubscriptionSelect from './SubscriptionSelect'
import { DARK_BLUE } from '../../../../../constants/colors'
import {
  SubscriptionResponse,
  UserMetaResponse,
} from '../../../../../redux/actions'
import { DeepReadonlyArray } from '../../../../../services'
import { TERMS, PRIVACY } from '../../../../../constants/routes'

interface Props {
  userMeta: UserMetaResponse
  subscriptions: DeepReadonlyArray<SubscriptionResponse>
  isActivating: boolean
  activateMembership(priceId: string): void
}

interface State {
  typeSelected: string
}

const getEndOfBillingPeriod = (timestamp: number): string => {
  const date = moment.unix(timestamp).format('MMMM Do, YYYY')
  return date
}

const isPastDate = (timestamp: number): boolean => moment().unix() > timestamp

const membershipActivationText = (status: string) =>
  status === 'canceled'
    ? "Please select membership option and select 'Activate Membership' to reactivate your account."
    : "Please select membership option and select 'Activate Membership' to create your membership."

class ActivateMembership extends PureComponent<Props, State> {
  state = {
    typeSelected: '',
  }

  handleSelect = (priceId: string) => {
    const { typeSelected } = this.state
    this.setState({
      typeSelected: typeSelected === priceId ? '' : priceId,
    })
  }

  handleActivateClicked = () => {
    const { activateMembership } = this.props
    const { typeSelected } = this.state

    if (!!typeSelected) {
      activateMembership(typeSelected)
    }
  }

  render() {
    const { subscriptions, userMeta, isActivating } = this.props
    const { typeSelected } = this.state

    return (
      <>
        <S.MembershipStatusWrap>
          <S.MembershipStatusText style={{ fontSize: 20, margin: 0 }}>
            Membership status:
          </S.MembershipStatusText>
          <S.MembershipStatus data-test={'membershipStatus'}>
            {userMeta.status === 'canceled' ? 'canceled' : 'no membership'}
          </S.MembershipStatus>
        </S.MembershipStatusWrap>
        <S.MembershipStatusWrap>
          <S.MembershipStatusText style={{ textAlign: 'center' }}>
            {membershipActivationText(userMeta.status)}
          </S.MembershipStatusText>
        </S.MembershipStatusWrap>
        {userMeta.status === 'canceled' && (
          <S.Text style={{ textAlign: 'center', marginTop: 0 }}>
            Your membership{' '}
            {isPastDate(userMeta.currentPeriodEnd) ? 'ended' : 'will end'} on{' '}
            {getEndOfBillingPeriod(userMeta.currentPeriodEnd)}
          </S.Text>
        )}
        {subscriptions.map((s: SubscriptionResponse) => (
          <SubscriptionSelect
            key={s.priceId}
            subscription={s}
            onSelect={() => this.handleSelect(s.priceId)}
            selected={s.priceId === typeSelected}
          />
        ))}
        <S.ActionButton
          isActive={!!typeSelected}
          onClick={() => this.handleActivateClicked()}
          data-test={'activateMembership'}
        >
          <S.ActionText>Activate Membership</S.ActionText>
          {isActivating && (
            <S.SpinnerWrap>
              <CircularProgress size={18} color="secondary" />
            </S.SpinnerWrap>
          )}
        </S.ActionButton>
        <S.Text>
          By selecting ‘Update Membership’ you agree to our{' '}
          <span
            onClick={() => navigate(TERMS)}
            style={{ cursor: 'pointer', color: DARK_BLUE }}
          >
            Terms of Service
          </span>{' '}
          and{' '}
          <span
            onClick={() => navigate(PRIVACY)}
            style={{ cursor: 'pointer', color: DARK_BLUE }}
          >
            Privacy Policy
          </span>
          .
        </S.Text>
      </>
    )
  }
}

export default ActivateMembership
