import styled from 'styled-components'
import { PRIMARY, SECONDARY } from '../constants/colors'

export const HeaderOptionsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
`

export const HeaderOption = styled.div<{ isSelected: boolean }>`
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: ${({ isSelected }) => (isSelected ? SECONDARY : PRIMARY)};
  padding: 0px 20px;
  text-transform: capitalize;
  cursor: pointer;

  &:hover {
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0.7)};
  }
`

export const HeaderOptionsWrapMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const HeaderOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 350px;
  cursor: pointer;
  padding: 12px 0px;

  &:hover {
    opacity: 0.7;
  }
`

export const HeaderOptionMobile = styled.div<{ isSelected: boolean }>`
  font-size: 22px;
  color: ${PRIMARY};
  text-transform: capitalize;
  color: ${({ isSelected }) => (isSelected ? SECONDARY : PRIMARY)};
`

export const Spacer = styled.div`
  padding: 12px 0px;
`
