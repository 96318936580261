import styled from 'styled-components'
import {
  BACKGROUND,
  PRIMARY,
  SECONDARY,
  SUBSCRIPTION_FILL,
  SUBSCRIPTION_OUTLINE,
  SUBSCRIPTION_TEXT_IS_SELECTED,
} from '../../../../constants/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const SubscriptionContainer = styled.div`
  display: flex;
  width: 350px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`

export const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const InfoPrimary = styled.div`
  font-size: 24px;
  line-height: 30px;
  color: ${PRIMARY};
  letter-spacing: 0.5px;
  text-transform: capitalize;
`

export const InfoSecondary = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: ${PRIMARY};
  letter-spacing: 0.5px;
`

export const SelectButton = styled.div<{ isSelected: boolean }>`
  display: flex;
  height: 40px;
  width: 100px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid
    ${({ isSelected }) =>
      isSelected ? SUBSCRIPTION_FILL : SUBSCRIPTION_OUTLINE};
  background-color: ${({ isSelected }) =>
    isSelected ? SUBSCRIPTION_FILL : 'transparent'};

  &:hover {
    opacity: 0.7;
  }
`

export const Text = styled.div<{ isSelected?: boolean }>`
  max-width: 350px;
  font-size: 16px;
  color: ${({ isSelected }) =>
    isSelected ? SUBSCRIPTION_TEXT_IS_SELECTED : PRIMARY};
  letter-spacing: 0.5px;
  margin: 24px 0px;
`

export const ActionButton = styled.div<{ isActive?: boolean }>`
  position: relative;
  display: flex;
  height: 40px;
  width: 300px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${PRIMARY};

  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};

  &:hover {
    opacity: 0.7;
  }
`

export const ActionText = styled.div`
  font-size: 17px;
  letter-spacing: 0.5px;
  color: ${BACKGROUND};
  text-align: center;
`

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 350px;
`

export const HalfButton = styled.div<{ color: string }>`
  display: flex;
  flex: 1;
  height: 40px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ color }) => color};
  border: 1px solid ${PRIMARY};

  &:hover {
    opacity: 0.7;
  }
`

export const ButtonText = styled.div<{ color: string }>`
  font-size: 17px;
  letter-spacing: 1.25px;
  color: ${({ color }) => color};
  text-align: center;
  font-weight: bold;
`

export const MembershipStatusWrap = styled.div`
  width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
`

export const MembershipStatusText = styled.div`
  font-size: 20px;
  color: ${PRIMARY};
  letter-spacing: 0.5px;
`

export const MembershipStatus = styled.div`
  font-size: 20px;
  color: ${SECONDARY};
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

export const SpinnerWrap = styled.div`
  position: absolute;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`
