import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'

import * as S from '../styles/more.styles'
import * as I from '../components/shared/Icons'
import Layout from '../templates/Layout'
import ManageMembership from '../components/More/Components/ManageMembership/ManageMembership'
import ManagePayment from '../components/More/Components/ManagePayment/ManagePayment'
import EditProfile from '../components/More/Components/EditProfile/EditProfile'
import ChangePassword from '../components/More/Components/ChangePassword/ChangePassword'
import * as session from '../services/session'
import { HOME_ROUTE } from '../constants/routes'
import { TABLET_WIDTH } from '../constants/measurements'
import { Section } from '../components/shared'
import { PRIMARY } from '../constants/colors'
import { RootState } from '../redux/reducers/rootReducers'
import {
  onFetchAthleteProfile as onFetchAthleteProfileAction,
  onFetchSubscriptions as onFetchSubscriptionsAction,
  onFetchUserMetadata as onFetchUserMetadataAction,
  onFetchUserPayment as onFetchUserPaymentAction,
} from '../redux/actions'
import { withErrorHandler } from '../components/errorHandler'

enum HeaderOptions {
  membership = 'Manage Membership',
  payment = 'Manage Payment',
  editProfile = 'Edit Profile',
  password = 'Change Password',
}

interface State {
  optionSelected: HeaderOptions | undefined
}

class More extends PureComponent<Props, State> {
  state = {
    optionSelected: HeaderOptions.membership,
  }

  componentDidMount() {
    const {
      onFetchSubscriptions,
      onFetchAthleteProfile,
      onFetchUserMetadata,
      onFetchUserPayment,
    } = this.props
    onFetchSubscriptions()
    onFetchAthleteProfile()
    onFetchUserMetadata()
    onFetchUserPayment()
  }

  updateUserMeta = () => {
    this.props.onFetchUserMetadata()
  }

  updateProfile = () => {
    this.props.onFetchAthleteProfile()
  }

  updatePaymentInfo = () => {
    this.props.onFetchUserPayment()
  }

  render() {
    session.redirectIfNotLoggedIn(HOME_ROUTE)

    const { optionSelected } = this.state
    const { subscriptions, profile, userMetadata, payment } = this.props
    return (
      <Layout>
        <Section>
          <MediaQuery maxWidth={TABLET_WIDTH}>
            {matches =>
              matches ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <S.HeaderOptionsWrapMobile>
                    {Object.values(HeaderOptions).map(
                      (option: HeaderOptions) => (
                        <>
                          <S.HeaderOptionContainer
                            key={option}
                            onClick={() => {
                              this.setState({
                                optionSelected:
                                  optionSelected === option
                                    ? undefined
                                    : option,
                              })
                            }}
                          >
                            <S.HeaderOptionMobile
                              isSelected={optionSelected === option}
                            >
                              {option}
                            </S.HeaderOptionMobile>
                            {option === optionSelected ? (
                              <I.MinusIcon color={PRIMARY} />
                            ) : (
                              <I.PlusIcon color={PRIMARY} />
                            )}
                          </S.HeaderOptionContainer>
                          {option === HeaderOptions.membership &&
                            optionSelected === HeaderOptions.membership && (
                              <S.Spacer>
                                <ManageMembership
                                  userMeta={userMetadata}
                                  subscriptions={subscriptions}
                                  updateUserMeta={this.updateUserMeta}
                                />
                              </S.Spacer>
                            )}
                          {option === HeaderOptions.payment &&
                            optionSelected === HeaderOptions.payment && (
                              <S.Spacer>
                                <ManagePayment
                                  paymentInfo={payment}
                                  update={this.updatePaymentInfo}
                                />
                              </S.Spacer>
                            )}
                          {option === HeaderOptions.editProfile &&
                            optionSelected === HeaderOptions.editProfile && (
                              <S.Spacer>
                                <EditProfile
                                  profile={profile}
                                  updateProfile={this.updateProfile}
                                />
                              </S.Spacer>
                            )}
                          {option === HeaderOptions.password &&
                            optionSelected === HeaderOptions.password && (
                              <S.Spacer>
                                <ChangePassword />
                              </S.Spacer>
                            )}
                        </>
                      ),
                    )}
                  </S.HeaderOptionsWrapMobile>
                </div>
              ) : (
                <>
                  <S.HeaderOptionsWrap>
                    {Object.values(HeaderOptions).map(option => (
                      <S.HeaderOption
                        key={option}
                        onClick={() =>
                          this.setState({ optionSelected: option })
                        }
                        isSelected={optionSelected === option}
                        data-test={`${option.split(' ').join('')}Header`}
                      >
                        {option}
                      </S.HeaderOption>
                    ))}
                  </S.HeaderOptionsWrap>
                  {optionSelected === HeaderOptions.membership && (
                    <ManageMembership
                      userMeta={userMetadata}
                      subscriptions={subscriptions}
                      updateUserMeta={this.updateUserMeta}
                    />
                  )}
                  {optionSelected === HeaderOptions.payment && (
                    <ManagePayment
                      paymentInfo={payment}
                      update={this.updatePaymentInfo}
                    />
                  )}
                  {optionSelected === HeaderOptions.editProfile && (
                    <EditProfile
                      profile={profile}
                      updateProfile={this.updateProfile}
                    />
                  )}
                  {optionSelected === HeaderOptions.password && (
                    <ChangePassword />
                  )}
                </>
              )
            }
          </MediaQuery>
        </Section>
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  subscriptions: state.entities.subscriptionsReducer.subscriptions,
  profile: state.entities.athleteProfileReducer.profile,
  userMetadata: state.entities.userMetadataReducer.userMetadata,
  payment: state.entities.userPaymentReducer.payment,
})

const mapDispatchToProps = {
  onFetchSubscriptions: onFetchSubscriptionsAction.request,
  onFetchAthleteProfile: onFetchAthleteProfileAction.request,
  onFetchUserMetadata: onFetchUserMetadataAction.request,
  onFetchUserPayment: onFetchUserPaymentAction.request,
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorHandler(More))
