/* eslint-disable camelcase */
import React, { useState } from 'react'
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import {
  loadStripe,
  PaymentMethod,
  StripeCardElement,
  StripeCardElementChangeEvent,
  StripeConstructorOptions,
} from '@stripe/stripe-js'
import CircularProgress from '@material-ui/core/CircularProgress'

import * as S from './ManagePayment.styles'
import { updateUserPayment } from '../../../../services/fetch/user'
import Snack from '../../../Snackbar'
import { UserPaymentResponse } from '../../../../redux/actions'
import { PRIMARY } from '../../../../constants/colors'

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: PRIMARY,
      color: PRIMARY,
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: 'black' },
      '::placeholder': { color: '#A2A2A6' },
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
  },
}
const connectedAccount = process.env.GATSBY_STRIPE_CONNECTED_ACCOUNT
const options: StripeConstructorOptions = connectedAccount
  ? {
      stripeAccount: connectedAccount,
    }
  : undefined

const stripeKey = process.env.GATSBY_STRIPE_PK
const stripePromise = loadStripe(stripeKey, options)

interface Props {
  paymentInfo: UserPaymentResponse | undefined
  update(): void
}

const ManagePayment: React.FC<Props> = (props: Props) => {
  const { paymentInfo, update } = props
  const [name, setName] = useState<string>('')
  const [isCardComplete, setIsCardComplete] = useState<boolean>(false)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const stripe = useStripe()
  const elements = useElements()

  const onCardChange = (event: StripeCardElementChangeEvent) => {
    const { complete } = event
    if (complete !== isCardComplete) {
      setIsCardComplete(complete)
    }
  }

  const canUpdate = () => name !== '' && isCardComplete

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setIsUpdating(true)

    const cardElement = elements.getElement(CardElement)

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement as StripeCardElement,
    })

    if (result.error) {
      // payment method failed
      setMessage(
        result.error.message || 'Something went wrong with your payment method',
      )
      setIsSuccess(false)
      setOpen(true)
      setIsUpdating(false)
    } else {
      // creating payment method successful
      const paymentMethod = result.paymentMethod as PaymentMethod
      updateUserPayment({
        paymentMethodId: paymentMethod?.id,
      })
        .then(() => {
          // success
          setMessage('Successfully updated payment method!')
          setIsSuccess(true)
          setOpen(true)

          cardElement.clear()

          update()
        })
        .catch((err: any) => {
          // error
          setMessage(
            err?.message || 'Something went wrong with your payment method',
          )
          setIsSuccess(false)
          setOpen(true)
        })
        .finally(() => {
          setIsUpdating(false)
          setName('')
        })
    }
  }

  return (
    <S.Container>
      <S.Primary>Payment Method</S.Primary>
      {paymentInfo && (
        <S.CurrentCardWrap>
          <S.Secondary
            data-test={'paymentMethodLastFour'}
          >{`${paymentInfo?.cardBrand.toUpperCase()} ending in ${
            paymentInfo?.lastFour
          }`}</S.Secondary>
          <S.Secondary
            data-test={'paymentMethodExp'}
          >{`Exp: ${paymentInfo?.expMonth}/${paymentInfo?.expYear}`}</S.Secondary>
        </S.CurrentCardWrap>
      )}

      <S.Primary style={{ marginBottom: 55 }}>Update Payment Method</S.Primary>
      <form style={{ marginBottom: 0 }} onSubmit={handleSubmit}>
        <S.FormInputDiv>
          <S.FormInput
            type="text"
            name="fullName"
            placeholder="Name on card"
            required
            value={name}
            onChange={e => setName(e.target.value)}
            data-test={'updatePaymentName'}
          />
        </S.FormInputDiv>
        <S.CardElementDiv>
          <CardElement options={CARD_OPTIONS as any} onChange={onCardChange} />
        </S.CardElementDiv>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <S.ActionButton isSelectable={canUpdate()} disabled={!canUpdate()}>
            <S.ActionText isSelectable={canUpdate()} data-test={'updatePaymentConfirm'}>
              Update Payment Method
            </S.ActionText>
            {isUpdating && (
              <S.SpinnerWrap>
                <CircularProgress size={18} color="secondary" />
              </S.SpinnerWrap>
            )}
          </S.ActionButton>
        </div>
      </form>
      <Snack
        open={open}
        isSuccess={isSuccess}
        message={message}
        close={() => setOpen(false)}
      />
    </S.Container>
  )
}

const InjectedManagePayment: React.FC<Props> = (props: Props) => (
  <Elements stripe={stripePromise}>
    <ManagePayment {...props} />
  </Elements>
)

export default InjectedManagePayment
