/* eslint-disable camelcase */
import request from '../request'
import { API_EDIT_PROFILE } from '../apiRoutes'

export interface AthleteProfileResponse {
  userId: number
  username: string
  firstName: string
  lastName: string
  birthday: string
  gender: string
  location: string
  phoneNumber: string
  profilePictureUrl: string
  email: string
}

export interface EditProfileRequest {
  username: string
  firstName: string
  lastName: string
  location: string
  email: string
  phoneNumber: string
}

export const editProfile = async (
  body: EditProfileRequest,
): Promise<AthleteProfileResponse> =>
  request(API_EDIT_PROFILE, body).then(res => res as AthleteProfileResponse)
