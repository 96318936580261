import Stripe from 'stripe'

import {
  API_CANCEL_SUBSCRIPTION,
  API_ACTIVATE_SUBSCRIPTION,
  API_UPGRADE_SUBSCRIPTION,
} from '../apiRoutes'
import request from '../request'

export const cancelSubscription = async (): Promise<Stripe.Subscription> =>
  request(API_CANCEL_SUBSCRIPTION).then(res => res as Stripe.Subscription)

export interface ActivateSubscriptionRequest {
  priceId: string
}

export const activateSubscription = async (
  body: ActivateSubscriptionRequest,
): Promise<Stripe.Subscription> =>
  request(API_ACTIVATE_SUBSCRIPTION, body).then(
    res => res as Stripe.Subscription,
  )

export interface UpgradeSubscriptionRequest {
  priceId: string
}

export const upgradeSubscription = async (
  body: UpgradeSubscriptionRequest,
): Promise<Stripe.Subscription> =>
  request(API_UPGRADE_SUBSCRIPTION, body).then(
    res => res as Stripe.Subscription,
  )
