import React, { useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  cancelSubscription,
  activateSubscription,
  upgradeSubscription,
} from '../../../../services/fetch/subscriptions'
import { UserMetaResponse } from '../../../../redux/actions'
import UpdateMembership from './Components/UpdateMembership'
import ActivateMembership from './Components/ActivateMembership'
import Snack from '../../../Snackbar'
import * as S from './ManageMembership.styles'
import { SubscriptionResponse } from '../../../../redux/actions'
import { DeepReadonlyArray } from '../../../../services'

interface Props {
  userMeta: UserMetaResponse | undefined
  subscriptions: DeepReadonlyArray<SubscriptionResponse>
  updateUserMeta(): void
}

const ManageMembership: React.FC<Props> = (props: Props) => {
  const { userMeta, subscriptions, updateUserMeta } = props
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [isCancelling, setIsCancelling] = useState<boolean>(false)
  const [isActivating, setIsActivating] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const cancelMembership = () => {
    setIsCancelling(true)
    cancelSubscription()
      .then(() => {
        setMessage('Successfully cancelled your membership.')
        setIsSuccess(true)
        setOpen(true)

        updateUserMeta()
      })
      .catch((err: any) => {
        setMessage(
          err?.message || 'Something went wrong when trying to cancel.',
        )
        setIsSuccess(false)
        setOpen(true)
      })
      .finally(() => setIsCancelling(false))
  }

  const activateMembership = (priceId: string) => {
    setIsActivating(true)
    activateSubscription({
      priceId,
    })
      .then(() => {
        setMessage('Successfully activated your membership!')
        setIsSuccess(true)
        setOpen(true)

        updateUserMeta()
      })
      .catch((err: any) => {
        setMessage(
          err?.message || 'Something went wrong when trying to cancel.',
        )
        setIsSuccess(false)
        setOpen(true)
      })
      .finally(() => setIsActivating(false))
  }

  const updateMembership = (priceId: string) => {
    if (priceId === userMeta?.priceId) {
      return
    }

    setIsUpdating(true)
    upgradeSubscription({
      priceId,
    })
      .then(() => {
        setMessage('Successfully upgrade your membership!')
        setIsSuccess(true)
        setOpen(true)

        updateUserMeta()
      })
      .catch((err: any) => {
        setMessage(
          err?.message || 'Something went wrong when trying to upgrade.',
        )
        setIsSuccess(false)
        setOpen(true)
      })
      .finally(() => setIsUpdating(false))
  }

  return (
    <S.Container>
      {userMeta ? (
        !['canceled', 'incomplete'].includes(userMeta.status) ? (
          <UpdateMembership
            userMeta={userMeta}
            subscriptions={subscriptions}
            updateMembership={(priceId: string) => updateMembership(priceId)}
            cancelMembership={() => cancelMembership()}
            isUpdating={isUpdating}
            isCancelling={isCancelling}
          />
        ) : (
          <ActivateMembership
            userMeta={userMeta}
            subscriptions={subscriptions}
            activateMembership={(priceId: string) =>
              activateMembership(priceId)
            }
            isActivating={isActivating}
          />
        )
      ) : (
        <CircularProgress size={40} color="secondary" />
      )}
      <Snack
        open={open}
        isSuccess={isSuccess}
        message={message}
        close={() => setOpen(false)}
      />
    </S.Container>
  )
}

export default ManageMembership
