import styled from 'styled-components'
import { BACKGROUND, PRIMARY } from '../../../../constants/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Primary = styled.div`
  font-size: 20px;
  color: ${PRIMARY};
  text-align: center;
  text-transform: capitalize;
`

export const CurrentCardWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 350px;
  justify-content: space-around;
  align-items: center;
  margin-top: 45px;
  margin-bottom: 65px;
`

export const Secondary = styled.div`
  font-size: 18px;
  color: ${PRIMARY};
`

export const ActionButton = styled.button<{ isSelectable: boolean }>`
  display: flex;
  height: 40px;
  width: 300px;
  position: relative;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ isSelectable }) =>
    isSelectable ? PRIMARY : 'transparent'};
  border: 1px solid ${PRIMARY};

  &:hover {
    opacity: 0.7;
  }
`

export const ActionText = styled.div<{ isSelectable: boolean }>`
  font-size: 17px;
  letter-spacing: 0.5px;
  color: ${({ isSelectable }) => (isSelectable ? BACKGROUND : PRIMARY)};
  text-align: center;
`

export const SpinnerWrap = styled.div`
  position: absolute;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FormInputDiv = styled.div`
  margin-bottom: 16px;
`

export const FormInput = styled.input`
  border: 1px solid ${PRIMARY};
  border-radius: 6px;
  font-size: 18px;
  text-align: left;
  color: ${PRIMARY};
  height: 40px;
  background-color: transparent;
  width: 350px;
  padding-left: 8px;

  ::placeholder {
    color: ${PRIMARY};
    opacity: 0.8;
  }
`

export const CardElementDiv = styled.div`
  border: 1px solid ${PRIMARY};
  padding: 8px;
  border-radius: 6px;
  width: 350px;
  margin-bottom: 48px;
  height: 40px;
`
