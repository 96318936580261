import React, { PureComponent } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import * as S from '../EditProfile/EditProfile.styles'
import {
  changePassword,
  ChangePasswordResponse,
} from '../../../../services/fetch/user'
import Snack from '../../../Snackbar'

interface State {
  currentPassword: string
  newPassword: string
  isUpdating: boolean
  open: boolean
  isSuccess: boolean
  message: string
}

class ChangePassword extends PureComponent<{}, State> {
  state = {
    currentPassword: '',
    newPassword: '',
    isUpdating: false,
    open: false,
    isSuccess: true,
    message: '',
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { newPassword, currentPassword } = this.state

    this.setState({
      isUpdating: true,
    })

    changePassword({
      oldPassword: currentPassword,
      newPassword,
    })
      .then((res: ChangePasswordResponse) => {
        this.setState({
          open: true,
          isSuccess: true,
          message: res.msg,
          isUpdating: false,
          currentPassword: '',
          newPassword: '',
        })
      })
      .catch(err => {
        this.setState({
          open: true,
          isSuccess: false,
          message: err.message,
          isUpdating: false,
          currentPassword: '',
          newPassword: '',
        })
      })
      .finally(() => {
        this.setState({
          isUpdating: false,
        })
      })
  }

  canSubmit = (): boolean => {
    const { newPassword, currentPassword } = this.state
    return newPassword !== '' && currentPassword !== '' ? true : false
  }

  render() {
    const {
      isUpdating,
      open,
      isSuccess,
      message,
      currentPassword,
      newPassword,
    } = this.state
    return (
      <S.Container>
        <form onSubmit={this.handleSubmit}>
          <S.FormWrap>
            <S.Label>Current Password</S.Label>
            <S.FormInputDiv>
              <S.FormInput
                type="password"
                name="currentPassword"
                placeholder="current password"
                required
                value={currentPassword}
                onChange={e =>
                  this.setState({ currentPassword: e.target.value })
                }
              />
            </S.FormInputDiv>
            <S.Label>New Password</S.Label>
            <S.FormInputDiv style={{ marginBottom: 0 }}>
              <S.FormInput
                type="password"
                name="newPassword"
                placeholder="new password"
                required
                value={newPassword}
                onChange={e => this.setState({ newPassword: e.target.value })}
              />
            </S.FormInputDiv>
            <S.ActionButton type="submit" isActive={this.canSubmit()}>
              <S.ActionText isActive={this.canSubmit()}>
                Update Password
              </S.ActionText>
              {isUpdating && (
                <S.SpinnerWrap>
                  <CircularProgress size={18} color="secondary" />
                </S.SpinnerWrap>
              )}
            </S.ActionButton>
          </S.FormWrap>
        </form>
        <Snack
          open={open}
          isSuccess={isSuccess}
          message={message}
          close={() =>
            this.setState({
              open: false,
            })
          }
        />
      </S.Container>
    )
  }
}

export default ChangePassword
