import styled from 'styled-components'
import { BACKGROUND, PRIMARY } from '../../../../constants/colors'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const FormWrap = styled.div`
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Label = styled.div`
  width: 350px;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: ${PRIMARY};
  margin-bottom: 12px;
`

export const FormInputDiv = styled.div`
  margin-bottom: 24px;
`

export const FormInput = styled.input`
  border: 1px solid ${PRIMARY};
  border-radius: 6px;
  font-size: 18px;
  text-align: left;
  color: ${PRIMARY};
  height: 40px;
  background-color: transparent;
  width: 350px;
  padding-left: 8px;

  ::placeholder {
    color: ${PRIMARY};
    opacity: 0.5;
  }
`

export const ActionButton = styled.button<{ isActive: boolean }>`
  position: relative;
  display: flex;
  height: 40px;
  width: 300px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? PRIMARY : 'transparent')};
  border: 1px solid ${PRIMARY};
  margin-top: 48px;
  &:hover {
    opacity: 0.7;
  }
`

export const ActionText = styled.div<{ isActive: boolean }>`
  font-size: 17px;
  letter-spacing: 0.5px;
  color: ${({ isActive }) => (isActive ? BACKGROUND : PRIMARY)};
  text-align: center;
`

export const SpinnerWrap = styled.div`
  position: absolute;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`
