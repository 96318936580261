import React, { PureComponent } from 'react'

import * as S from '../ManageMembership.styles'
import { SubscriptionResponse } from '../../../../../redux/actions'
import { getDisplayPrice } from '../../../../../services/currency-codes'

interface Props {
  subscription: SubscriptionResponse
  onSelect(): void
  selected: boolean
}

const getPrice = (amount: number, currency: string, type: string): string => {
  const displayPrice = getDisplayPrice(amount, currency)
  if (type === 'annual') {
    return `${displayPrice} / year`
  }
  return `${displayPrice} / month`
}

class SubscriptionSelect extends PureComponent<Props> {
  render() {
    const { subscription: s, selected, onSelect } = this.props
    return (
      <S.SubscriptionContainer key={`${s.priceId}`}>
        <S.InfoWrap>
          <S.InfoPrimary>{s.priceMetadata.type}</S.InfoPrimary>
          <S.InfoSecondary>
            {getPrice(s.amount, s.currency, s.priceMetadata.type)}
          </S.InfoSecondary>
          {s.priceMetadata.type === 'annual' && (
            <S.InfoSecondary style={{ fontSize: 14 }}>
              ({s.priceMetadata.detail})
            </S.InfoSecondary>
          )}
        </S.InfoWrap>
        <S.SelectButton 
          isSelected={selected}
          onClick={() => onSelect()}
          data-test={selected ? 'updateMembershipCurrent' : 'updateMembershipNew'}
        >
          <S.Text isSelected={selected}>
            {selected ? 'Selected' : 'Select'}
          </S.Text>
        </S.SelectButton>
      </S.SubscriptionContainer>
    )
  }
}

export default SubscriptionSelect
